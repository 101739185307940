* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#root {
    display: flex;
    height: 100%;
    flex-direction: column;
    background-color: white;
    align-items: center;
}

.App {
    margin-top: -40px;
    background-color: rgb(242, 242, 242);
}
