.main-root {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    min-width: 800px;
    width: 80%;
    padding: 20px;
    margin: -50px 0px 0px 0px;
    background-color: rgb(255, 255, 255);
}
