.navbar {
    width: 100vw;
    height: auto;
    display: flex;
    flex-flow: column wrap;
    align-items: start;
    background-color: #29619b;
    padding: 30px;
    color: white;
    font-family: 'Trebuchet MS', Verdana, sans-serif;
    font-size: 1rem;
}

.navbar-top-item {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.navbar-top {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.navbar-body {
    display: flex;
    margin-top: 20px;
    margin-bottom: 60px;
    font-size: 1.2rem;
}

.navbar h2 {
    font-size: 3rem;
}
/* 
.navbar__links {
    border: 2px solid rgb(0, 136, 255);
    font-size: 1.2rem;
    font-weight: bold;
    margin-left: auto;
    text-decoration: none !important;
    border: none !important;
}

.navbar__links p {
    color: rgb(80, 255, 255);
    line-height: 2;

    text-decoration: none !important;
    border: none !important;
} */
