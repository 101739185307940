.card-box-link {
    display: flex;
    align-self: stretch;
    margin: 20px 20px;
    text-decoration: none;
    color: rgb(80, 80, 80);
}

.card-box-link:hover {
    background-color: #ffffff;
    color: #29619b;
}

.card-box-link:active {
    background-color: #ffffff;
    color: #7772be;
}

.card-box {
    width: 360px;
    border: 1px solid rgb(208, 208, 208);
    margin-top: 10px;
    transition: 0.2s;
}

.card-box img {
    width: 100%;
    height: auto;
}

.card-box:hover {
    cursor: pointer;
    transform: scale(1.03);
    background-color: rgb(250, 250, 250);
    box-shadow: 0px 0px 20px rgb(208, 208, 208);
    transition: 0.2s;
}

.card-box .content {
    margin: 20px;
}

.content p {
    margin: 20px 0px 10px 0px;
    font-size: 1.1rem;
}

/*Ниже пример для анимации. Он никак тут не используется. Оставили его, чтобы можно было вспомнить как оно делается*/

.placeholder {
    width: 50px;
    height: 50px;
    background-color: #29619b;
    border-radius: 50%;
    position: relative;
    left: 0px;
    animation-name: card-hover2;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    /* animation-iteration-count: infinite; Бесконечно проигрывать анимацию */
    animation-direction: alternate;
    animation-play-state: running;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
}

.placeholder2 {
    width: 50px;
    height: 50px;
    background-color: blue;
    border-radius: 50%;
    position: relative;
    left: 0px;
    animation: card-hover2 2s ease-in-out 0.5s infinite forwards;
    animation-direction: alternate;
}
@keyframes card-hover {
    0% {
        transform: scale(1);
    }
    100% {
        background-color: rgb(202, 202, 202);
        transform: scale(1.1);
    }
}
@keyframes card-hover2 {
    0% {
        left: 0;
        background-color: blue;
    }
    50% {
        border-radius: 0%;
    }
    100% {
        left: 230px;
        background-color: rgb(163, 83, 83);
        transform: scale(1.5);
    }
}
